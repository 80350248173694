export default {
  employeeRequired: "שם עובד חובה",
  employeeNoRequired: "מספר עובד חובה",
  employeeNoInteger: "נא להקיש ערך נומיירי",
  bookkipingNoInteger: "נא להקיש ערך נומיירי",
  bookkipingNoValidMessage: 'מספר הנה"ח צריך להיות פחות מ 15 ספרות',
  phoneNoInteger: "מספר טלפון חייב להיות נומיירי",
  phoneNoValidMessage: "מספר טלפטומספר טלפון בין 7 עד 17 ספרות",
  daysInteger: "ימים חייב להיות נומיירי",
  daysValidation: "מספר ימים לפחות בין 0 עד 31 ספרות",
  monthsInteger: "חודשים חייב להיות נומיירי",
  monthsValidation: "מספר חודשים לפחות בין 0 עד 12 ספרות",
  faxInteger: "מספר פקס חייב להיות נומיירי",
  faxValidMessage: "מספר פקס לפחות בין 7 עד 17 ספרות",
  spaceValidValue: "נא הקש ערך חוקי",
  roleRequired: "נדרש תפקיד",
  customerRequired: "שם לקוח חובה",
  companyRequired: "שם חברה חובה",
  companyValidMessage: "שם חברה חייב להיות 5 אותיות או יותר",
  authorizedNoRequired: "נדרש מספר מורשה",
  contactRequired: "דרוש שם איש קשר",
  descriptionRequired: "דרוש תאור",
  tableNameRequired: "דרוש שם טבלה",
  emailRequired: "דרוש אי מייל",
  emailValidMessage: "הקש אי מייל חוקי",
  passwordRequired: "דרוש סיסמה",
  passwordValidMessage: "סיסמה בין 6 עד 20 ספרות",
  confirmValidMessage: "הסיסמה וסיסמת האישור שלך אינן תואמות",
  recordTypeRequired: "דרוש סוג רשומה",
  lengthValidMessage: "{fieldName} חייב להיות {type} ו {size} סימנים",
  amountValidMessage: "{fieldName} חייב להיות {type} ו {size}",
  decimalValidationMessage: "{fieldName} חייב להיות רק {size}",
  occasionalCustomerNoInteger: "הקש ערך נומיירי עבו לקוח מזדמן",
  imageSize: "גודל התמונה צריך להיות פחות מ-1 MB",
  formAlert: "נא בדוק את הערכים שרשמת",
  itemCodeRequired: "קוד פריט חובה",
  itemDescriptionRequired: "קוד פריט חובה",
  integerValue: "ערך מספרי בלבד",
  supplierRequired: "שם ספק חובה",
  quantityInteger: "הכמות צריכה להיות מספר",
  titleRequired: "כותרת להצעה חובה",
  productItemAlert: "מסמך חייב לכלול פריט אחד לפחות",
  invoiceItemAlert: "כַּרְטִיס חייבת לכלול לפחות חשבונית אחד",
  remarksItemAlert: "כַּרְטִיס חייבת לכלול לפחות הערות אחד",
  quotationRequired: "הצעת מחיר חובה",
  vatRegNoInteger: "עוסק מורשה מספר. הקש ערך נומיירי",
  orderRequired: "יש צורך בהזמנה",
  deliveryCompanyRequired: "נדרשת חברת משלוחים",
  ordernoInteger: "מספר הזמנה צריך להיות מספר",
  invoicenoInteger: "מספר חשבונית צריך להיות מספר",
  invoiceCompanyRequired: "חשבונית חובה",
  invoiceYearRequired: "נדרשת שנת חשבונית",
  receiptYearRequired: "נדרשת שנת קבלה",
  paymentDateRequired: "נדרשת תאריך תשלום",
  paymentTypeRequired: "נדרשת סוג תשלום",
  chequeNumberRequired: "נדרשת מספר המחאה/אישור",
  bankNoRequired: "נדרשת בנק/חברת אשראי",
  branchRequired: "נדרשת סניף/תוקף",
  accountNoRequired: "נדרשת מספר חשבון/כרטיס",
  amountRequired: "נדרשת סכום",
  paymentItemAlert: "כַּרְטִיס חייבת לכלול לפחות תשלום אחד",
  bankNameRequired: "דרוש שם בנק",
  customerOrderNoRequired: "הזמנת לקוח חובה",
  imageDimenstionRequired: "נדרש מקסימום {dimenstion} תמונה!",
  bookkipingNoRequired: 'נדרשת מספר הנה"ח',
  incomeRequired: "נדרשת הכנסה",
  vatExemptedIncomeRequired: 'נדרשת הכנסה פטורה ממע"מ',
  incomeVatRequired: 'נדרש מע"מ',
  purchasesRequired: 'נדרשת הכנסה לחו"ל',
  customerHoldingTaxRequired: "נדרש לקוח עם מס החזקה",
  balanceSheetSectionRequired: "יש צורך במאזן",
  grossProfitRequired: "נדרש רווח גולמי",
  expensesRequired: "נדרשות הוצאות",
  equipmentPurchaseVatRequired: 'נדרש מע"מ לרכישת ציוד',
  purchasesVatRequired: 'רכישות מחייבות מע"מ',
  supplierHoldingTaxRequired: "נדרש ספק עם ניכוי מס במקור",
  checksRequired: "נדרשות צ'קים",
  cashRequired: "נדרש מזומן",
  vatDebitCreditRequired: 'נדרש חיוב/אשראי מע"מ',
  balanceCancelationRequired: "נדרש ביטול יתרה",
  employeePrefixRequired: "נדרשת קידומת לעובד",
  customerPrefixRequired: "נדרשת קידומת לקוח",
  supplierPrefixRequired: "נדרשת קידומת לספק",
  securityCodeRequired: "קוד אבטחה נדרש",
  dateValid: "נא להזין תאריך חוקי בפורמט dd-mm-yyyy",
  dateValid2: "נא להזין תאריך חוקי בפורמט yyyy-mm-dd",
  documentAttachedAlert: "כרטיס חייב לכלול לפחות מסמך אחד",
  fileRequired: "נדרש מסמך מצורף",
  detialsRequired: "נדרשים פרטים",
  documentTypeRequired: "נדרש סוג מסמך",
  discountRequired: "נדרשת הנחה",
  priceRequired: "מחיר נדרש",
  itemGroupRequired: "נדרשת קבוצת פריטים",
  priceListGroupRequired: "נדרשת קבוצת מחירונים",
  fromNoRequired: "מתוך מספר נדרש",
  documentNumberRequired: "נדרש מספר מסמך",
  seriesRequired: "חובה סדרה",
  addressRequired: "כתובת נדרשת",
  startTimeRequired: "נדרשת שעת התחלה",
  endTimeRequired: "נדרשת שעת סיום",
  eventDaysInteger: "ימי האירוע בלוח השנה צריכים להיות מספר",
  eventDaysValidMessage: "ימי אירוע ביומן חייבים להכיל פחות מ-3 תווים",
  priorityRequired: "נדרשת עדיפות",
  eventTypeRequired: "נדרש סוג האירוע",
  grandTotalRequestedInteger: "הסכום הכולל המבוקש צריך להיות מספר",
  lessGrandTotalRequested: "הסכום הכולל המבוקש צריך להיות פחות מהסך הכולל",
  lessPaidAmount: "הסכום ששולם צריך להיות קטן או שווה לסך הכולל",
  suppliedQtyRequired: "נדרשת כמות מסופקת",
  untilDateRequired: "נדרש תאריך סיום",
  fromDateRequired: "נדרש תאריך התחלה",
  yearRequired: "שנה נדרשת",
  priceHigh: "אנא בדוק שסך החשבונית אינו עולה על הסכום הכולל של חשבונית האשראי",
  dateRequired: "נדרש תאריך",
  documentDetailsRequired: "נדרשים פרטי מסמך",
  invalidFileFormat: "נא להזין קובץ doc",
  updateWareHouse: "יש להזין ערך בשדה מרשימת המחסנים.",
  fontSizeRequired: "נדרש גודל גופן",
  fontStyleRequired: "נדרש סגנון גופן",
  fontWeightRequired: "נדרש משקל גופן",
  templateSelectionValid: "נדרשת תבנית",
  templateNameRequired: "נדרש שם תבנית",
  textDecorationRequired: "נדרש עיטור טקסט",
  suppliedQty: "הכמות המסופקת אינה גדולה מהכמות",
  quotationNoInteger: "ציטוט מס'. צריך להיות מספר",
  quotationNoValidMessage: "מספר הצעת מחיר חייב להכיל פחות מ-8 תווים",
  receivedByRequired: "התקבל על ידי נדרש",
  deliveryNoteNoRequired: "חובה מס' תעודת משלוח",
  deliveryNoteNoInteger: "מס' תעודת משלוח צריך להיות מספר",
  fixedDiscountInteger: "הנחה קבועה צריכה להיות מספר",
  fixedDiscountValidMessage: "הנחה קבועה חייבת להיות פחות מ-2 תווים",
  warehouseRequired: "יש צורך במחסן",
  invoiceNoRequired: "דרוש מס' חשבונית",
  creditInvoiceNoRequired: "נדרשת מספר חשבונית אשראי",
  creditInvoicenoInteger: "מספר חשבונית אשראי צריך להיות מספר",
  discountValueExceedsLimit: "ערך ההנחה לא יכול לעלות על 100.",
  negativeValueMessage: "אל תזין ערכים שליליים",
  refreshToken:
    'למשתמש אין אסימון רענון אז אנא לחץ על כפתור גוגל והיכנס עם הדוא"ל שלך.',
  decimalPoint: "נדרש שדה עשרוני",
  decimalNoRequired: "נדרש מספר עשרוני",
  maxValueExceeded: "{fieldName} אסור לחרוג {maxValue}.",
};
